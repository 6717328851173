@import url(https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Signika+Negative:wght@300;400;600;700&display=swap);
/* CSS Mini Reset http://www.vcarrer.com/2010/05/css-mini-reset.html */
html, body, div, form, fieldset, legend, label {
    margin: 0;
    padding: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

th, td {
    text-align: left;
    vertical-align: top;
}

h1, h2, h3, h4, h5, h6, th, td, caption {font-weight: normal;}

img {border: 0;}

/* Overall Styles */
:root {
    font-size: 18px;
}

* {
    box-sizing: border-box;

    --primary: #4b2f86;
    --secondary: #5ea8af;
    --tertiary: #4b2f86;
    --background: #fff;
    --text-color: #24143d;
    --inverse-text-color: #fff;
    --text-link: #428086;
    --xxx-small: 0.313rem;
    --xx-small: 0.438rem;
    --x-small: 0.563rem;
    --small: 0.75rem;
    --medium: 1rem;
    --large: 1.313rem;
    --x-large: 1.75rem;
    --xx-large: 2.313rem;
    --xxx-large: 3.125rem;
    --xxxx-large: 4.188rem;
    --xxxxx-large: 5.188rem;
}

body {
    font-family: "Signika Negative", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 1rem;
}

code {
    font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New", monospace;
}

p {
    font-size: var(--medium);
}

@media (prefers-color-scheme: dark) {
    * {
        --primary: #5ea8af;
        --secondary: #8666c9;
        --tertiary: #f89fba;
        --background: #19161f;
        --text-color: #adaaa7;
        --text-link: #8e70cc;
        --inverse-text-color: #0a0909;
    }
}

body {
    background-color: var(--background);
    color: var(--text-color);
}

a {
    color: var(--text-link);
    font-size: var(--medium);
}

a[target="_blank"]::after {
    content: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAAQElEQVR42qXKwQkAIAxDUUdxtO6/RBQkQZvSi8I/pL4BoGw/XPkh4XigPmsUgh0626AjRsgxHTkUThsG2T/sIlzdTsp52kSS1wAAAABJRU5ErkJggg==");
    margin: 0 3px 0 5px;
}

a:visited {
    color: var(--secondary) * 20%;
}

h1 {
    color: var(--primary);
    font-size: var(--xx-large);
    font-family: "Permanent Marker", cursive;
}

h2 {
    color: var(--primary);
    font-size: var(--x-large);
    font-family: "Permanent Marker", cursive;
}

h3 {
    color: var(--primary);
    font-size: var(--large);
    font-family: "Permanent Marker", cursive;
}

.site-content {
    margin: 0 auto;
    max-width: 1200px;
    padding: 0 var(--medium);
}

.site-header {
    background-image: linear-gradient(to right, var(--secondary) 25%, var(--tertiary) 100%);
    color: var(--inverse-text-color);
    display: flex;
    margin: -1rem -1rem var(--small);
    padding: var(--small) var(--large) var(--xxx-small);
    height: 3.25rem;
}

.site-header a.site-header__logo-link {
    background-color: rgba(75, 47, 134, 0.7);
    border-radius: 0;
    padding: 2.5rem 0.5rem 1rem;
    margin: -1rem 0 0;
    font-weight: 500;
    height: 5rem;
}

@media (prefers-color-scheme: dark) {
    .site-header a.site-header__logo-link {
        background-color: rgba(248, 159, 186, 0.7);
    }
}

.site-header__logo {
    max-height: 32px;
}

.site-header nav {
    flex-grow: 5;
    margin-top: 0.4rem;
    text-align: right;
}

.site-header a {
    border-bottom: 2px solid transparent;
    font-weight: 500;
    margin: 0 0.5rem;
    padding-bottom: 0.4rem;
    text-decoration: none;
}

.site-footer a {
    color: var(--inverse-text-color);
}

.site-header a:hover,
.site-header a:focus {
    border-bottom-color: var(--secondary);
}

a.site-header__logo-link:hover {
    border-bottom-color: var(--tertiary);
}

.overview {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 47rem) {
    .overview {
        flex-direction: row;
    }

    .overview__intro {
        flex-grow: 2;
        width: 67%;
    }

    .overview__toc {
        width: 33%;
    }
}

.stages {
    margin-bottom: -0.9rem;
}

.projects {
    position: relative;
}

@media screen and (min-width: 27rem) {
    .projects::before {
        content: ">";
        position: absolute;
        font-family: "Signika Negative", sans-serif;
        color: var(--primary);
        text-align: left;
        line-height: 1.3rem;
        font-size: 2rem;
        width: 100%;
        margin-top: 1.9rem;
        padding-left: 20rem;
    }
}
@media screen and (min-width: 32rem) {
    .projects::before {
        content: ">\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0>";
    }
}
@media screen and (min-width: 36rem) {
    .projects::before {
        content: ">\00a0\00a0\00a0\00a0\00a0\00a0\00a0>\00a0\00a0\00a0\00a0\00a0\00a0\00a0>";
    }
}
@media screen and (min-width: 55rem) {
    .projects::before {
        content: ">\00a0\00a0\00a0\00a0\00a0\00a0\00a0>\00a0\00a0\00a0\00a0\00a0\00a0\00a0>";
        text-align: center;
        padding-left: 0;
    }
}

.project-overview {
    border-image: linear-gradient(to right, var(--secondary) 0%, var(--tertiary) 100%);
    border-image-slice: 1;
    border-top: 2px solid;
    border-right: 4px solid;
    border-bottom: 2px solid;
    margin: 0;
    padding: 1.5rem;
    width: 100%;
}

.project-overview::before {
    background-color: var(--primary);
    border-radius: 50%;
    color: #fff;
    content: attr(data-time);
    font-family: "Signika Negative", sans-serif;
    font-size: 1rem;
    height: 2.5rem;
    line-height: 1.3rem;
    margin-top: 8rem;
    padding: 15px;
    position: absolute;
    text-align: center;
    vertical-align: baseline;
    width: 2.5rem;
    z-index: 2;
}

.project-overview:nth-of-type(odd)::before {
    left: 100%;
    margin-left: -2.25rem;
}

.project-overview:nth-of-type(even)::before {
    right: 100%;
    margin-right: -2.25rem;
}

.project-overview::after {
    content: "<\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0<\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0<";
    position: absolute;
    font-family: "Signika Negative", sans-serif;
    color: var(--primary);
    text-align: center;
    line-height: 1.3rem;
    font-size: 2rem;
    width: 100%;
    margin-top: 0.95rem;
}

.project-overview:nth-of-type(even)::after {
    content: ">\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0>\00a0\00a0\00a0\00a0\00a0\00a0\00a0\00a0>";
}

.project-overview:nth-last-of-type(1)::after {
    content: "";
}

.project-overview:nth-of-type(even) {
    border-left: 3px solid var(--primary);
    border-right: 0;
    border-top-width: 2px;
}

.project-overview:nth-of-type(1) {
    border-top-width: 4px;
}

.project-overview:nth-last-of-type(1) {
    border-bottom-width: 0;
}

.project-overview__name {
    margin: 0 0 1rem;
}

.project-overview__body-wrap {
    display: flex;
    flex-direction: column;
}

@media screen and (min-width: 50rem) {
    .project-overview__body-wrap {
        flex-direction: row;
    }

    .project-overview:nth-child(even) .project-overview__body-wrap {
        flex-direction: row-reverse;
    }
}

.project-overview__button {
    background-color: var(--secondary);
    border: 1px solid var(--secondary);
    border-radius: 5px;
    color: var(--background);
    display: inline-block;
    font-weight: 600;
    margin-bottom: 2.5rem;
    padding: 0.5rem 1rem;
    text-decoration: none;
    width: auto;
}

.project-overview__button:active,
.project-overview__button:focus,
.project-overview__button:hover,
.project-overview__button:visited {
    background-color: var(--tertiary);
    border-color: var(--tertiary);
    color: var(--inverse-text-color);
}

.project-overview__image-box {
    flex-grow: 2;
    position: relative;
}

.project-overview__description {
    position: relative;
}

@media screen and (min-width: 50rem) {
    .project-overview__description {
        margin-left: 1rem;
        margin-top: 0;
        max-width: 50%;
    }

    .project-overview:nth-child(even) .project-overview__description {
        margin-left: 0;
        margin-right: 1rem;
    }

    .project-overview__text {
        margin-top: 0;
    }

    .project-overview__text h3 {
        margin-top: 0;
    }
}
@media screen and (min-width: 64rem) {
    .project-overview__description {
        max-width: 33%;
    }
}

.project-overview__image-box a[target="_blank"]::after {
    background-color: #fff;
    border-radius: 5px;
    margin: 0.5rem 0 0 -2.5rem;
    padding: 0.5rem 0.75rem;
    position: absolute;
}

.image-box__img {
    height: auto;
    width: 100%;
}

.image-box__desc {
    font-style: italic;
}

.jump-to-top {
    bottom: 0;
    position: absolute;
    right: 0;
}

@media screen and (min-width: 36rem) {
    .project-overview:nth-child(even) .jump-to-top {
        left: 0;
    }
}

.site-footer {
    background-image: linear-gradient(to right, var(--secondary) 25%, var(--tertiary) 100%);
    color: var(--inverse-text-color);
    margin: 3rem -1rem -1rem;
    padding: 1rem;
    text-align: center;
}

/* Temporarily removed button until I have more content ready */
a.project-overview__button {
    display: none;
}

.project-overview__text {
    margin-bottom: 2.5rem;
}

