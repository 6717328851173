@import url("https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Signika+Negative:wght@300;400;600;700&display=swap");

/* CSS Mini Reset http://www.vcarrer.com/2010/05/css-mini-reset.html */
html, body, div, form, fieldset, legend, label {
    margin: 0;
    padding: 0;
}

table {
    border-collapse: collapse;
    border-spacing: 0;
}

th, td {
    text-align: left;
    vertical-align: top;
}

h1, h2, h3, h4, h5, h6, th, td, caption {font-weight: normal;}

img {border: 0;}

/* Overall Styles */
:root {
    font-size: 18px;
}

* {
    box-sizing: border-box;

    --primary: #4b2f86;
    --secondary: #5ea8af;
    --tertiary: #4b2f86;
    --background: #fff;
    --text-color: #24143d;
    --inverse-text-color: #fff;
    --text-link: #428086;
    --xxx-small: 0.313rem;
    --xx-small: 0.438rem;
    --x-small: 0.563rem;
    --small: 0.75rem;
    --medium: 1rem;
    --large: 1.313rem;
    --x-large: 1.75rem;
    --xx-large: 2.313rem;
    --xxx-large: 3.125rem;
    --xxxx-large: 4.188rem;
    --xxxxx-large: 5.188rem;
}

body {
    font-family: "Signika Negative", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 1rem;
}

code {
    font-family: "source-code-pro", "Menlo", "Monaco", "Consolas", "Courier New", monospace;
}

p {
    font-size: var(--medium);
}

@media (prefers-color-scheme: dark) {
    * {
        --primary: #5ea8af;
        --secondary: #8666c9;
        --tertiary: #f89fba;
        --background: #19161f;
        --text-color: #adaaa7;
        --text-link: #8e70cc;
        --inverse-text-color: #0a0909;
    }
}
